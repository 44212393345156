import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Container, Card, CardBody, Button, CustomInput } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { withSendRequest } from '../../main/hoc/withSendRequest';
import { 
  getEntryData, 
  getExperimentInfo, 
  setSearch, 
  clearEntryDataError, 
  clearExperimentInfoError,
  getFairData
} from '../actions/experimentOverview';
import Loading from '../../main/components/loading';
import ExperimentVariableContainer from '../components/experimentVariableContainer';
import ExperimentViewHeader from './experimentViewHeader';
import { getEntryDataRequestParams } from '../utils';
import VariablesPagination from './variablesPagination';
import RawDataModal from '../components/rawDataModal';
import ExperimentNotFoundAlert from '../components/experimentNotFoundAlert';
import * as notify from '../../main/utils/notify'; 
import { __env } from '../../envloader';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import FairDataModal from '../components/fairDataModal';

const ExperimentView = ({ match, sendRequest }) => {
  const [ openRawDataModal, setOpenRawDataModal ] = useState(false);
  const [ openFairDataModal, setOpenFairDataModal ] = useState(false);
  const [ switchEmpty, setSwitchEmpty ] = useState(false);

  const search = window.location.search;
  const experimentId = match.params.id;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const entryDataLoading = useSelector((state) => state.experimentOverview.get('experimentData').get('loading'));
  const entryDataError = useSelector((state) => state.experimentOverview.get('experimentData').get('error'));
  const entryData = useSelector((state) => state.experimentOverview.get('experimentData').get('data'));

  const experimentInfoLoading = useSelector((state) => state.experimentOverview.get('experimentInfo').get('loading'));
  const experimentInfoData = useSelector((state) => state.experimentOverview.get('experimentInfo').get('data'));
  const experimentInfoError = useSelector((state) => state.experimentOverview.get('experimentInfo').get('error'));
  const source = useSelector((state) => state.experimentOverview.get('experimentInfo').get('source'));
  const experimentInfoExpId = useSelector((state) => state.experimentOverview.get('experimentInfo').get('experimentId'));

  const fairDataLoading = useSelector((state) => state.experimentOverview.get('fairData').get('loading'));
  const fairDataError = useSelector((state) => state.experimentOverview.get('fairData').get('error'));
  
  useEffect(() => {
    if (!experimentInfoExpId || experimentId !== experimentInfoExpId) {
      if (!experimentInfoLoading && !experimentInfoError) {
        dispatch(getExperimentInfo({ sendRequest, experimentId }));
      }
  
      if (!fairDataLoading && !fairDataError) {
        dispatch(getFairData({ sendRequest, experimentId }));
      }
    }
  }, [
    sendRequest,
    dispatch,
    experimentId,
    experimentInfoExpId,
    experimentInfoLoading,
    experimentInfoError,
    fairDataError,
    fairDataLoading
  ]);
  
  useEffect(() => {
    if (search) {
      const { filters, page } = getEntryDataRequestParams(search);
      dispatch(setSearch(search));
      dispatch(getEntryData({ sendRequest, experimentId, page, filters }));
    }
    else {
      dispatch(setSearch(''));
      dispatch(getEntryData({ sendRequest, experimentId, page: 1 }));
    }
  }, [ sendRequest, search, experimentId, dispatch ]);

  useEffect(() => {
    return () => {
      dispatch(clearEntryDataError());
      dispatch(clearExperimentInfoError());
    };
  }, [ dispatch ]);

  const onOpenJupyter = async () => {
    try {
      const res = await axios.post(`${__env.JUPYTER_API_URL}/data_access_jupyter`, {
        uri: source
      });

      const html = res.data;
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const link = doc.querySelector('a').getAttribute('href');

      window.open(link, '_blank').focus();
    } 
    catch (err) {
      notify.error(
        t('experiment_view.jupyter_notebook_error.title'),
        t('experiment_view.jupyter_notebook_error.description')
      );
    }
  };

  const onToggleRawDataModal = () => setOpenRawDataModal((prev) => !prev);

  const onToggleFairDataModal = () => setOpenFairDataModal((prev) => !prev);

  const handleToggle = () => setSwitchEmpty((prev) => !prev);

  if (entryDataError) {
    return <ExperimentNotFoundAlert error={entryDataError} experimentId={experimentId} />;
  }

  return (
    <Container className='mt-3'>
      <RawDataModal
        onOpenJupyter={onOpenJupyter}
        isOpen={openRawDataModal}
        toggle={onToggleRawDataModal}
        source={source}
        machine={experimentInfoData ? experimentInfoData.machine : ''}
        sourceAddress="#"
      />
      <FairDataModal isOpen={openFairDataModal} onToggle={onToggleFairDataModal} />
      <Card>
        {(experimentInfoLoading || !experimentInfoData) ? (
          <Loading className='text-center' />
        ) : (
          <ExperimentViewHeader experimentId={experimentId} />
        )}
        <CardBody>
          {(entryDataLoading || !entryData) ? (
            <Loading className='text-center' />
          ) : (
            <>
              <div className='d-flex justify-content-between align-items-center'>
                <p className='m-0'>{t('experiment_view.source_uri_label')}:{' '}{source}</p>
                <div className='d-flex align-items-center'>
                  <CustomInput
                    type='switch'
                    id='switch-empty-value'
                    name='switch-empty-value'
                    label={t('variable_switch_value_empty')}
                    checked={switchEmpty}
                    onChange={(e) => handleToggle(e)}
                    className={'mr-3'}
                  />
                  <Button type='button' onClick={onToggleFairDataModal} className='mr-2 min-w-110'>
                    {t('experiment_view.fair_data_button')}
                  </Button> 
                  <Button type='button' onClick={onToggleRawDataModal} className='min-w-110'>
                    {t('experiment_view.open_raw_data_modal_button')}
                  </Button>
                </div>
              </div>
              <hr />
              {entryData.map((item) => (
                <ExperimentVariableContainer
                  key={item.idsField}
                  idsField={item.idsField}
                  variableType={item.variableType}
                  variableTimeDependent={item.variableTimeDependent}
                  groupedEntryData={item.groupedEntryData}
                  showEmpty={switchEmpty}
                  idsTypeId={item.idsTypeId}
                />
              ))}
            </> 
          )}
        </CardBody>
      </Card>
      <VariablesPagination experimentId={experimentId} />
    </Container>
  );
};

ExperimentView.propTypes = {
  match: PropTypes.object.isRequired, // HOC
  sendRequest: PropTypes.func.isRequired, // HOC
};

export default compose(
  withSendRequest,
  ErrorBoundary((props) => props.t('components.experiment_view'))
)(ExperimentView);

